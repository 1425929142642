<template>
  <div v-if="!isMobile" class="container-pc">
    <div style="background-color: #193db3; padding-top: 0.1px">
      <!-- ======= Header ======= -->
      <header id="header" class="d-flex align-items-center">
        <div class="container d-flex align-items-center justify-content-between">
          <h1 class="logo">
            <a><img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/2024/03/25/575f2b4917254290be12e5a5be3c5be1/logo.png" alt="" /></a>
          </h1>
          <nav id="navbar" class="navbar">
            <ul>
              <li><a class="nav-link scrollto active" href="#hero">{{ isCN ? "首页" : "Home" }}</a></li>
<!--              <li class="dropdown">
                <a>
                  {{ isCN ? "日程" : "Program" }}
                  <i class="bi bi-chevron-down"/>
                </a>
                <ul class="huigu">
                  <template v-if="isCN">
                    <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864" target="_blank">会议日程</a></li>
                    <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864/search?agendaId=1394574515748864" target="_blank">讲者检索</a></li>
                  </template>
                  <template v-else>
                    <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864?en=1" target="_blank">Forums</a></li>
                    <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864/search?agendaId=1394574515748864&en=1" target="_blank">Speakers</a></li>
                  </template>
                </ul>
              </li>-->
              <li>
                <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/course/3328"
                  target="_blank"
                >{{ isCN ? "网络直播" : "Webcast" }}</a>
              </li>
              <li>
                <a
                  class="nav-link scrollto"
                  :href="isCN?'https://www.drvoice.cn/v2/conf/1120/column/1405621418328320':'https://www.drvoice.cn/v2/conf/1120/column/1405621418328320?language=en'"
                  target="_blank"
                >Webinars</a>
              </li>
              <li v-if="isCN">
                <a
                  class="nav-link scrollto"
                  href="https://www.pailixiang.com/m/album/main_ig65930788.html"
                  target="_blank"
                >照片直播</a>
              </li>
<!--              <li v-if="isCN">
                <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/cvh2024/workshop"
                >Workshop预约</a>
              </li>
              <li v-if="!isCN">
                <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/conf/1120/column/1400736462077952?language=en"
                >Download</a>
              </li>
              <li class="dropdown" v-if="isCN">
                <a>
                  会议资料
                  <i class="bi bi-chevron-down"/>
                </a>
                <ul class="huigu">
                  <li><a href="https://www.drvoice.cn/v2/conf/1120/column/1403064991039744">参会指南</a></li>
                  <li><a href="https://www.drvoice.cn/v2/conf/1120/column/1404045771178240">会议资讯</a></li>
                  <li><a href="https://www.drvoice.cn/v2/conf/1120/column/1400736361062400">资料下载</a></li>
                </ul>
              </li>
              <li v-if="!isCN">
                <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/conf/1120/column/1404045771178240"
                >News</a>
              </li>
              <li >
                <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/special/553"
                >{{isCN? "病例大赛" : "Case"}}</a>
              </li>
              <li v-if="isCN">
                <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/sroom/1120"
                >云展厅</a>
              </li>-->
              <li class="dropdown">
                <a>
                  {{ isCN ? "回顾" : "Review" }}
                  <i class="bi bi-chevron-down"/>
                </a>
                <ul class="huigu">
                  <li><a @click="goPrevious(2024)">2024</a></li>
                  <li><a @click="goPrevious(2023)">2023</a></li>
                  <li><a @click="goPrevious(2022)">2022</a></li>
                  <li><a @click="goPrevious(2021)">2021</a></li>
                  <li><a @click="goPrevious(2020)">2020</a></li>
                  <li><a @click="goPrevious(2019)">2019</a></li>
                  <li><a @click="goPrevious(2018)">2018</a></li>
                  <li><a @click="goPrevious(2017)">2017</a></li>
                  <li><a @click="goPrevious(2016)">2016</a></li>
                </ul>
              </li>
<!--              <li>
                <a
                  :href="isCN ? 'https://www.drvoice.cn/v2/conf/1120/home' : 'https://www.drvoice.cn/v2/conf/1120/home?language=en'"
                >{{ isCN ? "登录注册" : "Sign up" }}</a>
              </li>-->
            </ul>
            <div class="lang-check" @click="isCN = !isCN">
              {{ isCN ? "EN | 英文" : "CN | 中文" }}
            </div>
            <i @click="mobileNavToggle" class="bi bi-list mobile-nav-toggle"/>
          </nav>
        </div>
      </header>
      <!-- ======= Hero Section ======= -->
      <section id="hero">
        <div class="container">
          <div data-aos="zoom-out" class="text">
            <h1>
              {{ isCN ? "CHINA VALVE（HANGZHOU）" : "CHINA VALVE（HANGZHOU）" }}
            </h1>
            <h2>
              {{ isCN ? "2025年4月14-20日" : "APRIL 14-20, 2025" }}&nbsp;
              {{ isCN ? "中国杭州" : "HANGZHOU, CHINA" }}
            </h2>
            <div class="yy">
              {{ isCN ? "CHINA VALVE（HANGZHOU）" : "CHINA VALVE（HANGZHOU）" }}
            </div>
          </div>
          <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100" style="overflow: hidden;">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="row">
                  <div
                    class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                    style="justify-content: center"
                  >
                    <div class="img-box" data-aos="zoom-out" data-aos-delay="100">
                      <img src="@/assets/img/slices/heroImg/hero-1.jpg" alt="" />
                    </div>
                  </div>
                  <div
                    class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                    style="justify-content: center"
                  >
                    <div class="img-box" data-aos="zoom-out" data-aos-delay="200">
                      <img src="@/assets/img/slices/heroImg/hero-2.jpg" alt="" />
                    </div>
                  </div>
                  <div
                    class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                    style="justify-content: center"
                  >
                    <div class="img-box" data-aos="zoom-out" data-aos-delay="300">
                      <img src="@/assets/img/slices/heroImg/hero-3.jpg" alt="" />
                    </div>
                  </div>
                  <div
                    class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                    style="justify-content: center"
                  >
                    <div class="img-box" data-aos="zoom-out" data-aos-delay="400">
                      <img src="@/assets/img/slices/heroImg/hero-4.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="row">
                  <div
                    class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                    style="justify-content: center"
                  >
                    <div class="img-box" data-aos="zoom-out" data-aos-delay="100">
                      <img src="@/assets/img/slices/heroImg/hero-5.jpg" alt="" />
                    </div>
                  </div>
                  <div
                    class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                    style="justify-content: center"
                  >
                    <div class="img-box" data-aos="zoom-out" data-aos-delay="200">
                      <img src="@/assets/img/slices/heroImg/hero-6.jpg" alt="" />
                    </div>
                  </div>
                  <div
                    class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                    style="justify-content: center"
                  >
                    <div class="img-box" data-aos="zoom-out" data-aos-delay="300">
                      <img src="@/assets/img/slices/heroImg/hero-7.jpg" alt="" />
                    </div>
                  </div>
                  <div
                    class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                    style="justify-content: center"
                  >
                    <div class="img-box" data-aos="zoom-out" data-aos-delay="400">
                      <img src="@/assets/img/slices/heroImg/hero-8.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="swiper-pagination"></div> -->
          </div>
        </div>
        <svg
          class="hero-waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28 "
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="wave-path"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g class="wave1">
            <use
              xlink:href="#wave-path"
              x="50"
              y="3"
              fill="rgba(255,255,255, .1)"
            />
          </g>
          <g class="wave2">
            <use
              xlink:href="#wave-path"
              x="50"
              y="0"
              fill="rgba(255,255,255, .2)"
            />
          </g>
          <g class="wave3">
            <use xlink:href="#wave-path" x="50" y="9" fill="#fff" />
          </g>
        </svg>
      </section>
    </div>

    <main id="main">
      <!-- ======= Featured Services Section ======= -->
      <section id="featured-services" class="featured-services">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              @click="goSignUp"
            >
              <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div class="icon"><i class="bi bi-person-plus-fill"></i></div>
                <h4 class="title">
                  <a
                    >{{ isCN ? "2025年2月5日" : "February 5,2025" }}</a
                  >
                </h4>
                <p class="description">
                  {{ isCN ? "官网开放注册" : "Early-Bird Registration" }}
                </p>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
            >
              <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
                <div class="icon">
                  <i class="bi bi-calendar2-event-fill"></i>
                </div>
                <h4 class="title">
                  <a>{{ isCN ? "2025年4月8日" : "April 8,2025" }}</a>
                </h4>
                <p class="description">
                  {{ isCN ? "日程公布" : "Program Available" }}
                </p>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
            >
              <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div class="icon"><i class="bi bi-bookmark-plus-fill"></i></div>
                <h4 class="title">
                  <a>{{ isCN ? "2025年4月14日" : "April 14, 2025" }}</a
                  >
                </h4>
                <p class="description">
                  {{ isCN ? "会议开始" : "The Meeting Starts" }}
                </p>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
            >
              <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div class="icon">
                  <i class="bi bi-clipboard2-pulse-fill"></i>
                </div>
                <h4 class="title">
                  <a>{{ isCN ? "2025年4月20日" : "April 20,2025" }}</a>
                </h4>
                <p class="description">
                  {{ isCN ? "会议闭幕" : "Closing Ceremony" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="webinar-series" class="webinar-series">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h3>{{ isCN ? "Webinars" : "Webinars" }}</h3>
          </div>
          <a href="https://www.drvoice.cn/v2/course/3538" target="_blank">
            <div class="ws-box">
              <div class="ws-lt">
                <div class="ws-title">
                  CHINA VALVE – MOUNT SINAI Structural Heart Webinar
                </div>
                <div class="ws-subtitle">
                  Tailoring TEER therapy with MitraClip
                </div>
                <div class="ws-time"><span style="fontWeight:bold;">Time(CN): 2024-11-14 20:30 - 21:30</span></div>
              </div>
              <div class="ws-img">
                <img class="bg" src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/11/08/32af592736274ee88da9e11a2028168c.png" alt="">
                <img class="img" src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/11/08/32af592736274ee88da9e11a2028168c.png" alt="">
              </div>
            </div>
          </a>
        </div>
      </section>

      <!-- ======= About Section ======= -->
      <section id="about" class="about section-bg">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h3>{{ isCN ? "欢迎辞" : "Welcoming Speech" }}</h3>
          </div>

          <div class="row">
            <div
              data-aos="fade-up"
              data-aos-delay="100"
              class="col-lg-12 pt-4 pt-lg-0 content d-flex flex-column justify-content-center"
            >
              <template v-if="isCN">
                <p>尊敬的各位专家和同道：</p>
                <p style="text-indent: 2em;">
                  岁月如碑，铭刻年轮。当暖春熏风拂过钱江两岸，一年一度的CHINA VALVE（HANGZHOU）也将于2025年4月14日至20日在杭州如期举办。在此，诚挚邀请各位专家同道参与本届学术盛会！
                </p>
                <template v-if="isAllText">
                  <p style="text-indent: 2em;">
                    本届大会将一如既往联动国际知名学术组织，汇聚瓣膜及结构性心脏病领域资深专家、领军企业、杰出学科带头人，与大咖共话瓣膜领域未来；通过Global Live Demo & 全国手术联播，在深耕经导管主动脉瓣置换术（TAVR）的基础上，探索经导管二尖瓣修复/置换术（TMVr/R）、经导管三尖瓣修复/置换术（TTVr/R）、经导管肺动脉瓣置换术（TPVR）的前沿阵地，全面展现全球范围内最先进诊疗器械、理念与技术；开设Learn from Masters、Case-Based Learning等栏目，针对性设置workshop，涵盖解剖、CT、血管缝合器等多个专场，更有心超、麻醉、基础研究等学术专场，为准备开展瓣膜介入治疗的中心提供系统性培训，为已经初步开展瓣膜介入治疗的中心提供技术提高的机会，为经验丰富的瓣膜介入治疗中心提供经验交流与合作的途径，力争为广大参会医师打造一个国际化、高水平、多元、开放的瓣膜平台。
                  </p>
                  <p style="text-indent: 2em;">风雨和星光仿若昨日，第二个十年元年已开启，时间将再次开启新的计量。CHINA VALVE（HANGZHOU）2025将继续在问与答、察与思、辨与析中深度思考，问道技术发展，以未来之约、奋斗之姿，开启瓣膜病诊疗学术交流日新月异、精彩纷呈的新篇章。</p>
                  <p style="text-indent: 2em;">我们衷心感谢各位同道一直以来的大力支持和厚爱，也衷心期待各位专家、各位同道在百忙之中应邀参会，与您相约春色芳菲的四月杭州，共同推动我国瓣膜病诊疗事业的蓬勃发展！</p>
                </template>
              </template>
              <template v-else>
                <p>Distinguished experts and colleagues,</p>
                <p class="p-section">
                  The ages resemble monuments, on which time leaves its prints as annual rings. The annual CHINA VALVE (HANGZHOU) will once again take place in Hangzhou from April 14th to April 20st , 2025, when the warm spring breeze blows across the banks of the Qianjiang River. Here, we cordially welcome all specialists to take part in this academic gathering!
                </p>
                <template v-if="isAllText">
                  <p class="p-section">
                    As usual, this conference will collaborate with globally recognized academic institutions, convene top professionals, and exceptional discipline leaders in the field of structural and valvular heart disease to talk about the future of the field. Based on the mastery of transcatheter aortic valve replacement (TAVR), the conference will explore the frontiers of transcatheter mitral valve repair/replacement (TMVr/R), transcatheter tricuspid valve repair/replacement (TTVr/R), and transcatheter pulmonary valve replacement (TPVR) through the Global & National Live Demos. We will also comprehensively demonstrate the most cutting-edge diagnosis and treatment instruments, concepts, and technologies in the world. The conference provides programs like Learn from Masters, Case-Based Learning, and specialized seminars on anatomy, CT, and vascular staplers. More importantly, there will be academic presentations on cardiac ultrasound, anesthesia, and fundamental research. For centers intending to do valvular intervention, the conference will offer methodical training; for newly formed centers, it will offer technical enhancements; and for established centers, it will offer opportunities for cooperation and experience sharing. The conference's overall goal is to establish a global, elite, diverse, and open-access platform for participating physicians.
                  </p>
                  <p class="p-section">
                    Time marches on, just like the seasons change. The events of the previous decade still seem like they happened yesterday while a new decade has already begun. With the commitment to strive for a better future, CHINA VALVE (HANGZHOU) 2025 will continue to think deeply and pursue technological advancements by asking valuable questions, making thoughtful observations and applying analytical discernment so as to open a new chapter of amazing academic exchanges in valve disease diagnosis and treatment.
                  </p>
                  <p class="p-section">
                    We sincerely thank each and every one of our colleagues for their unwavering enthusiasm and support, and we hope that all experts and colleagues will be able to attend the conference despite their hectic schedules. We look forward to seeing you in Hangzhou in the spring time of April to accelerate the advancement of China valve!
                  </p>
                </template>
              </template>
              <div class="about-bottom" v-if="!isAllText">
                <div class="rt-btn" @click="isAllText = true">
                  {{ isCN ? "查看更多" : "read more" }}
                </div>
              </div>
            </div>
          </div>
          <div class="sign-list" data-aos="fade-up" v-if="isAllText">
            <div class="sign-img">
              <div
                style="
                  display: flex;
                  flex: 1;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/02/21/1a95a989ad4749ae891bb7adcdca51c8.png"
                />
              </div>
              <div>{{ isCN ? "葛均波" : "Jun-bo GE" }}</div>
            </div>
            <div class="sign-img">
              <div
                style="
                  display: flex;
                  flex: 1;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/02/15/bf3e09ad8d7543118e2fa879c702ba28.png"
                />
              </div>
              <div>{{ isCN ? "王建安" : `Jian'an Wang` }}</div>
            </div>
            <div class="sign-img">
              <div
                style="
                  display: flex;
                  flex: 1;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/02/21/b487d0cf31df4d3180f41528b6064d66.png"
                />
              </div>
              <div>Horst Sievert</div>
            </div>
          </div>
        </div>
      </section>

      <!-- ======= Services Section ======= -->
      <section id="previews" class="previews">
        <div class="container" data-aos="fade-up" data-aos-delay="200">
          <div class="section-title">
            <h3>{{ isCN ? "会议亮点" : "Conference Highlights" }}</h3>
          </div>
          <div class="row">
            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              style="justify-content: center"
            >
              <div class="CH-card" data-aos="fade-up" data-aos-delay="400">
                <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/03/29/3f04a3b662b0408cb6c36735dab12023.png" alt="" />
                <h4>{{ isCN ? "向大师学习 " : "Learn from Masters" }}</h4>
                <p v-if="isCN">荟萃全球瓣膜介入名家，传道受业解惑</p>
                <p v-else>
                  Gather world-renowned interventional cardiologist，impart professional knowledge, and resolve doubts
                </p>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              style="justify-content: center"
            >
              <div class="CH-card" data-aos="fade-up" data-aos-delay="400">
                <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/03/29/a27b752e44e84cf2aadf39c5fb0331aa.png" alt="" />
                <h4>
                  {{
                    isCN ? "基于病例学习" : "Case Based Learning"
                  }}
                </h4>
                <p v-if="isCN">聚焦病例剖根源，探寻临床诊疗新路径</p>
                <p v-else>
                  Focus on the root causes of the case, explore new ways of clinical diagnosis and treatment
                </p>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              style="justify-content: center"
            >
              <div class="CH-card" data-aos="fade-up" data-aos-delay="400">
                <img src="@/assets/img/slices/global-live.png" alt="" />
                <h4>{{ isCN ? "全球手术演示" : "Global Live Demo" }}</h4>
                <p v-if="isCN">汇集直播和录播病例，呈现最新技术和器械</p>
                <p v-else>
                  Present the latest clinical technique and devices all over the
                  world by live and recorded cases
                </p>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              style="justify-content: center"
            >
              <div class="CH-card" data-aos="fade-up" data-aos-delay="400">
                <img src="@/assets/img/slices/national-live.png" alt="" />
                <h4>{{ isCN ? "国内手术演示" : "National Live Demo" }}</h4>
                <p v-if="isCN">深化国内各中心合作与交流，展示中国瓣膜创新</p>
                <p v-else>
                  Deepen the collaboration and communication nationwide
                </p>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              style="justify-content: center"
            >
              <div class="CH-card" data-aos="fade-up" data-aos-delay="400">
                <img src="@/assets/img/slices/learning.png" alt="" />
                <h4>{{ isCN ? "手把手带教" : "Step By Step Learning" }}</h4>
                <p v-if="isCN">提供系统化培训，推进瓣膜中心建设</p>
                <p v-else>
                  Provide systematic training for centers to carry out valvular
                  interventions or improve techniques
                </p>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              style="justify-content: center"
            >
              <div class="CH-card" data-aos="fade-up" data-aos-delay="400">
                <img src="@/assets/img/slices/research.png" alt="" />
                <h4>{{ isCN ? "基础研究论坛" : "Basic Research Forum" }}</h4>
                <p v-if="isCN">交流瓣膜领域最新基础研究成果</p>
                <p v-else>
                  Exhibit and exchange latest results of basic researches on
                  cardiac valvular diseases
                </p>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              style="justify-content: center"
            >
              <div class="CH-card" data-aos="fade-up" data-aos-delay="400">
                <img src="@/assets/img/slices/multi-dimensional.png" alt="" />
                <h4>
                  {{
                    isCN
                      ? "多元学习平台"
                      : "Multi-dimensional Platform of Learning"
                  }}
                </h4>
                <p v-if="isCN">覆盖解剖、CT、心超、麻醉、护理等多领域</p>
                <p v-else>
                  Cover special sessions such as anatomy, CT, echocardiography,
                  anesthesia and nursing forums
                </p>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              style="justify-content: center"
            >
              <div class="CH-card" data-aos="fade-up" data-aos-delay="400">
                <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/03/29/7e95f042a1ea49fc819838237cb5e3ea.png" alt="" />
                <h4>{{ isCN ? "培训工坊" : "Workshop" }}</h4>
                <p v-if="isCN">VR、模拟器、穿刺点缝合、猪心解剖，知行合一再提升</p>
                <p v-else>
                  VR, simulator operation, puncture point suture, heart anatomy, promote the unity of inner knowledge and action
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <!-- ======= Footer ======= -->
    <footer id="footer" class="footer">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h3>{{ isCN ? "联系我们" : "Contact Us" }}</h3>
        </div>
        <div class="row">
          <div class="hosted">
            <h5>{{ isCN ? "主办单位" : "HOSTED BY" }}</h5>
            <p>
              {{
                isCN
                  ? "浙江大学医学院附属第二医院"
                  : "Second Affiliated Hospital, Zhejiang University School of Medicine (SAHZU)"
              }}
              <br />
              <a href="https://www.csi-congress.org/" target="_blank" style="color:#2D2D2D;">
                {{
                  isCN
                    ? "结构性心脏大会CSI"
                    : "Catheter Interventions in Congenital, Structural and Valvular Heart Diseases (CSI)"
                }}
              </a>
            </p>
          </div>
          <div class="foot-bottom">
            <div class="tp">
              <div class="foot-item">
                <div class="icon"><i class="bi bi-calendar2-check"></i></div>
                <div class="item-rt">
                  <h5>{{ isCN ? "会议时间：" : "CONFERENCE DATES" }}</h5>
                  <p>
                    {{
                      isCN
                        ? "2025年4月14-20日"
                        : "April.14, 2025- April.20, 2025"
                    }}
                  </p>
                </div>
              </div>
              <div class="foot-item">
                <div class="icon"><i class="bi bi-globe2"></i></div>
                <div class="item-rt">
                  <h5>{{ isCN ? "大会官网：" : "WEBSITE" }}</h5>
                  <p>http://www.china-valve.org.cn</p>
                </div>
              </div>
            </div>
            <div class="bt">
              <template v-if="isCN">
                <div class="foot-item">
                  <div class="icon"><i class="bi bi-envelope"></i></div>
                  <div class="item-rt">
                    <h5>联系方式</h5>
                    <p>
                      13805784967
                      <br/>
                      cvh2025@china-valve.org.cn
                    </p>
                  </div>
                </div>
                <div class="foot-item">
                  <div class="icon"><i class="bi bi-geo-alt"></i></div>
                  <div class="item-rt">
                    <h5>会场地点</h5>
                    <p style="font-size:15px">
                      杭州洲际酒店（浙江省杭州市上城区解放东路2号）
                    </p>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="foot-item">
                  <div class="icon"><i class="bi bi-telephone"></i></div>
                  <div class="item-rt">
                    <h5>{{ isCN ? "会务组联系电话：" : "CONTACT" }}</h5>
                    <p>
                      {{
                        isCN ? "13805784967" : "+86 13805784967"
                      }}
                    </p>
                  </div>
                </div>
                <div class="foot-item">
                  <div class="icon"><i class="bi bi-envelope"></i></div>
                  <div class="item-rt">
                    <h5>{{ isCN ? "电子邮箱：" : "EMAIL" }}</h5>
                    <p>cvh2025@china-valve.org.cn</p>
                  </div>
                </div>
              </template>
            </div>

            <div class="bt">
              <a href="https://www.csi-congress.org/" target="_black" class="foot-item">
                <div class="icon" style="padding-top:29px">
                  <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/05/13/f0232c8af9eb4ef9b9523856675a7cd4.png" width="50px" height="50px" style="vertical-align:top">
                </div>
                <div class="item-rt">
                  <h5>{{ isCN ? "合作伙伴" : "PARTNER" }}</h5>
                  <p>
                    CSI:  &nbsp;<span style="text-decoration: underline;">https://www.csi-congress.org/</span>
                  </p>
                </div>
              </a>
            </div>

          </div>
        </div>
      </div>
      <div style="text-align: center">
        <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #aaa"
          >京ICP备15021671号-3</a
        >
      </div>
    </footer>
    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
    >
      <i class="bi bi-arrow-up-short"></i>
    </a>
  </div>
  <div v-else class="container-mb">
    <header id="header" class="d-flex align-items-center">
      <div class="container d-flex align-items-center justify-content-between">
        <div></div>
        <nav id="navbar" class="navbar">
          <div class="lang-check" @click="isCN = !isCN">
            {{ isCN ? "EN | 英文" : "CN | 中文" }}
          </div>
          <ul>
            <li>
              <a
                @click="navSelectHandle($event, '#hero')"
                class="nav-link scrollto active"
                href="#hero"
                >{{ isCN ? "首页" : "Home" }}</a
              >
            </li>
<!--            <li class="dropdown" @click="forumsDropdownHandle">
              <a
              >{{ isCN ? "日程" : "Program" }}
                <i class="bi bi-chevron-down"></i
                ></a>
              <ul class="forums">
                <template v-if="isCN">
                  <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864">会议日程</a></li>
                  <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864/search?agendaId=1394574515748864">讲者检索</a></li>
                </template>
                <template v-else>
                  <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864?en=1">Forums</a></li>
                  <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864/search?agendaId=1394574515748864&en=1">Speakers</a></li>
                </template>
              </ul>
            </li>-->
            <li>
              <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/course/3328"
              >{{ isCN ? "网络直播" : "Webcast" }}</a>
            </li>
            <li>
              <a
                  class="nav-link scrollto"
                  :href="isCN?'https://www.drvoice.cn/v2/conf/1120/column/1405621418328320':'https://www.drvoice.cn/v2/conf/1120/column/1405621418328320?language=en'"
              >Webinars</a>
            </li>
            <li v-if="isCN">
              <a
                  class="nav-link scrollto"
                  href="https://www.pailixiang.com/m/album/main_ig65930788.html"
              >照片直播</a>
            </li>
<!--            <li v-if="isCN">
              <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/cvh2024/workshop"
              >Workshop预约</a>
            </li>
            <li v-if="!isCN">
              <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/conf/1120/column/1400736462077952?language=en"
              >Download</a>
            </li>
            <li class="dropdown" v-if="isCN">
            <li class="dropdown" @click="dropdownHandles">
              <a href="#"
              >会议资料
                <i class="bi bi-chevron-down"></i
                ></a>
              <ul class="presenter">
                <li><a href="https://www.drvoice.cn/v2/conf/1120/column/1403064991039744">参会指南</a></li>
                <li><a href="https://www.drvoice.cn/v2/conf/1120/column/1404045771178240">会议资讯</a></li>
                <li><a href="https://www.drvoice.cn/v2/conf/1120/column/1400736361062400">资料下载</a></li>
              </ul>
            </li>
            <li v-if="!isCN">
              <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/conf/1120/column/1404045771178240"
              >News</a>
            </li>
            <li >
              <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/special/553"
              >{{isCN? "病例大赛" : "Case"}}</a>
            </li>
            <li v-if="isCN">
              <a
                  class="nav-link scrollto"
                  href="https://www.drvoice.cn/v2/sroom/1120"
              >云展厅</a>
            </li>-->
            <li class="dropdown" @click="dropdownHandle">
              <a>
                {{ isCN ? "回顾" : "Review" }}
                <i class="bi bi-chevron-down"/>
              </a>
              <ul class="huigu">
                <li><a @click="goPrevious(2024)">2024</a></li>
                <li><a @click="goPrevious(2023)">2023</a></li>
                <li><a @click="goPrevious(2022)">2022</a></li>
                <li><a @click="goPrevious(2021)">2021</a></li>
                <li><a @click="goPrevious(2020)">2020</a></li>
                <li><a @click="goPrevious(2019)">2019</a></li>
                <li><a @click="goPrevious(2018)">2018</a></li>
                <li><a @click="goPrevious(2017)">2017</a></li>
                <li><a @click="goPrevious(2016)">2016</a></li>
              </ul>
            </li>
<!--            <li>
              <a
                :href="
                  isCN
                    ? 'https://www.drvoice.cn/v2/conf/1120/delegationRegister'
                      : 'https://www.drvoice.cn/v2/conf/1120/delegationRegister?language=en'
                "
                >{{ isCN ? "注册" : "Sign up" }}</a
              >
            </li>-->
          </ul>
          <i @click="mobileNavToggle" class="bi bi-list mobile-nav-toggle"></i>
        </nav>
      </div>
    </header>
    <div class="main">
      <section id="hero">
        <div class="container">
          <div
            class="col-lg-7 pt-lg-0 order-2 order-lg-1 d-flex align-items-center"
          >
            <div data-aos="zoom-out" class="text">
              <h1>
                {{
                  isCN ? "CHINA VALVE（HANGZHOU）" : "CHINA VALVE（HANGZHOU）"
                }}
              </h1>
              <h2>
                {{ isCN ? "2025年4月14-20日" : "APRIL 14-20, 2025" }}&nbsp;
                {{ isCN ? "中国杭州" : "HANGZHOU, CHINA" }}
              </h2>
              <div class="yy">
                {{
                  isCN ? "CHINA VALVE（HANGZHOU）" : "CHINA VALVE（HANGZHOU）"
                }}
              </div>
            </div>
          </div>
          <div class="box">
            <div class="img-list">
              <div class="img-box" data-aos="zoom-out" data-aos-delay="100">
                <img src="@/assets/img/slices/heroImg/hero-1.jpg" alt="" />
              </div>
              <div class="img-box" data-aos="zoom-out" data-aos-delay="200">
                <img src="@/assets/img/slices/heroImg/hero-2.jpg" alt="" />
              </div>
              <div class="img-box" data-aos="zoom-out" data-aos-delay="300">
                <img src="@/assets/img/slices/heroImg/hero-3.jpg" alt="" />
              </div>
              <div class="img-box" data-aos="zoom-out" data-aos-delay="400">
                <img src="@/assets/img/slices/heroImg/hero-4.jpg" alt="" />
              </div>
              <div class="img-box" data-aos="zoom-out" data-aos-delay="500">
                <img src="@/assets/img/slices/heroImg/hero-5.jpg" alt="" />
              </div>
              <div class="img-box" data-aos="zoom-out" data-aos-delay="600">
                <img src="@/assets/img/slices/heroImg/hero-6.jpg" alt="" />
              </div>
              <div class="img-box" data-aos="zoom-out" data-aos-delay="700">
                <img src="@/assets/img/slices/heroImg/hero-7.jpg" alt="" />
              </div>
              <div class="img-box" data-aos="zoom-out" data-aos-delay="800">
                <img src="@/assets/img/slices/heroImg/hero-8.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <svg
          class="hero-waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28 "
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="wave-path"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g class="wave1">
            <use
              xlink:href="#wave-path"
              x="50"
              y="3"
              fill="rgba(255,255,255, .1)"
            />
          </g>
          <g class="wave2">
            <use
              xlink:href="#wave-path"
              x="50"
              y="0"
              fill="rgba(255,255,255, .2)"
            />
          </g>
          <g class="wave3">
            <use xlink:href="#wave-path" x="50" y="9" fill="#fff" />
          </g>
        </svg>
      </section>
      <div class="programe" id="featured-services" data-aos="fade-up">
        <div class="programe-item" @click="goSignUp">
          <div class="lt">
            <div class="item-time">
              {{ isCN ? "2025年2月5日" : "February 5,2025" }}
            </div>
            <div class="item-text">
              {{ isCN ? "官网开放注册" : "Early-Bird Registration" }}
            </div>
          </div>
          <div class="icon"><i class="bi bi-person-plus-fill"></i></div>
        </div>
        <div
          class="programe-item">
          <div class="lt">
            <div class="item-time">
              {{ isCN ? "2025年4月8日" : "April 8,2025" }}
            </div>
            <div class="item-text">
              {{ isCN ? "日程公布" : "Program Available" }}
            </div>
          </div>
          <div class="icon"><i class="bi bi-bookmark-plus-fill"></i></div>
        </div>
        <div class="programe-item">
          <div class="lt">
            <div class="item-time">
              {{ isCN ? "2025年4月14日" : "April 14, 2025" }}
            </div>
            <div class="item-text">
              {{ isCN ? "会议开始" : "The meeting starts" }}
            </div>
          </div>
          <div class="icon"><i class="bi bi-calendar2-event-fill"></i></div>
        </div>
        <div class="programe-item">
          <div class="lt">
            <div class="item-time">
              {{ isCN ? "2025年4月20日" : "April 20,2025" }}
            </div>
            <div class="item-text">
              {{ isCN ? "会议闭幕" : "Closing Ceremony" }}
            </div>
          </div>
          <div class="icon"><i class="bi bi-clipboard2-pulse-fill"></i></div>
        </div>
      </div>
      <div class="welcoming" id="about" data-aos="fade-up">
        <div class="mb-title">{{ isCN ? "欢迎辞" : "WELCOMING SPEECH" }}</div>
        <div class="" :class="{ isfold: isfold }" style="width: 100%">
          <template v-if="isCN">
            <div class="p">尊敬的各位专家和同道：</div>
            <div class="p">
              岁月如碑，铭刻年轮。当暖春熏风拂过钱江两岸，一年一度的CHINA VALVE（HANGZHOU）也将于2025年4月14日至20日在杭州如期举办。在此，诚挚邀请各位专家同道参与本届学术盛会！
            </div>
            <div class="p">
              本届大会将一如既往联动国际知名学术组织，汇聚瓣膜及结构性心脏病领域资深专家、领军企业、杰出学科带头人，与大咖共话瓣膜领域未来；通过Global Live Demo & 全国手术联播，在深耕经导管主动脉瓣置换术（TAVR）的基础上，探索经导管二尖瓣修复/置换术（TMVr/R）、经导管三尖瓣修复/置换术（TTVr/R）、经导管肺动脉瓣置换术（TPVR）的前沿阵地，全面展现全球范围内最先进诊疗器械、理念与技术；开设Learn from Masters、Case-Based Learning等栏目，针对性设置workshop，涵盖解剖、CT、血管缝合器等多个专场，更有心超、麻醉、基础研究等学术专场，为准备开展瓣膜介入治疗的中心提供系统性培训，为已经初步开展瓣膜介入治疗的中心提供技术提高的机会，为经验丰富的瓣膜介入治疗中心提供经验交流与合作的途径，力争为广大参会医师打造一个国际化、高水平、多元、开放的瓣膜平台。
            </div>
            <div class="p">
              风雨和星光仿若昨日，第二个十年元年已开启，时间将再次开启新的计量。CHINA VALVE（HANGZHOU）2025将继续在问与答、察与思、辨与析中深度思考，问道技术发展，以未来之约、奋斗之姿，开启瓣膜病诊疗学术交流日新月异、精彩纷呈的新篇章。
            </div>
            <div class="p">我们衷心感谢各位同道一直以来的大力支持和厚爱，也衷心期待各位专家、各位同道在百忙之中应邀参会，与您相约春色芳菲的四月杭州，共同推动我国瓣膜病诊疗事业的蓬勃发展！</div>
          </template>
          <template v-else>
            <div class="p">Distinguished experts and colleagues,</div>
            <div class="p">
              The ages resemble monuments, on which time leaves its prints as annual rings. The annual CHINA VALVE (HANGZHOU) will once again take place in Hangzhou from April 14th to April 20st , 2025, when the warm spring breeze blows across the banks of the Qianjiang River. Here, we cordially welcome all specialists to take part in this academic gathering!
            </div>
            <div class="p">
              As usual, this conference will collaborate with globally recognized academic institutions, convene top professionals, and exceptional discipline leaders in the field of structural and valvular heart disease to talk about the future of the field. Based on the mastery of transcatheter aortic valve replacement (TAVR), the conference will explore the frontiers of transcatheter mitral valve repair/replacement (TMVr/R), transcatheter tricuspid valve repair/replacement (TTVr/R), and transcatheter pulmonary valve replacement (TPVR) through the Global & National Live Demos. We will also comprehensively demonstrate the most cutting-edge diagnosis and treatment instruments, concepts, and technologies in the world. The conference provides programs like Learn from Masters, Case-Based Learning, and specialized seminars on anatomy, CT, and vascular staplers. More importantly, there will be academic presentations on cardiac ultrasound, anesthesia, and fundamental research. For centers intending to do valvular intervention, the conference will offer methodical training; for newly formed centers, it will offer technical enhancements; and for established centers, it will offer opportunities for cooperation and experience sharing. The conference's overall goal is to establish a global, elite, diverse, and open-access platform for participating physicians.
            </div>
            <div class="p">
              Time marches on, just like the seasons change. The events of the previous decade still seem like they happened yesterday while a new decade has already begun. With the commitment to strive for a better future, CHINA VALVE (HANGZHOU) 2025 will continue to think deeply and pursue technological advancements by asking valuable questions, making thoughtful observations and applying analytical discernment so as to open a new chapter of amazing academic exchanges in valve disease diagnosis and treatment.
            </div>
            <div class="p">
              We sincerely thank each and every one of our colleagues for their unwavering enthusiasm and support, and we hope that all experts and colleagues will be able to attend the conference despite their hectic schedules. We look forward to seeing you in Hangzhou in the spring time of April to accelerate the advancement of China valve!
            </div>
          </template>
        </div>
        <div class="switch" v-if="isfold" @click="isfold = false">
          {{ isCN ? "查看更多" : "MORE" }}
          <br />
          <i class="bi bi-chevron-down"></i>
        </div>
        <div class="sign-list" v-if="!isfold">
          <div class="sign-img">
            <div
              style="
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
            >
              <img
                src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/02/21/1a95a989ad4749ae891bb7adcdca51c8.png"
              />
            </div>
            <div>{{ isCN ? "葛均波" : "Jun-bo GE" }}</div>
          </div>
          <div class="sign-img">
            <div
              style="
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
            >
              <img
                src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/02/15/bf3e09ad8d7543118e2fa879c702ba28.png"
              />
            </div>
            <div>{{ isCN ? "王建安" : `Jian'an Wang` }}</div>
          </div>
          <div class="sign-img">
            <div
              style="
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
            >
              <img
                src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/02/21/b487d0cf31df4d3180f41528b6064d66.png"
              />
            </div>
            <div>Horst Sievert</div>
          </div>
        </div>
      </div>
      <div
        class="previews"
        id="previews"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <div class="mb-title">
          {{ isCN ? "会议亮点" : "Conference Highlights" }}
        </div>
        <div class="previews-list">
          <div class="previews-item">
            <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/03/29/3f04a3b662b0408cb6c36735dab12023.png" alt="" />
            <h4>{{ isCN ? "向大师学习" : "Learn from Masters" }}</h4>
            <p v-if="isCN">荟萃全球瓣膜介入名家，传道受业解惑</p>
            <p v-else>
              Gather world-renowned interventional cardiologist，impart professional knowledge, and resolve doubts
            </p>
          </div>
          <div class="previews-item">
            <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/03/29/a27b752e44e84cf2aadf39c5fb0331aa.png" alt="" />
            <h4>
              {{ isCN ? "基于病例学习" : "Case Based Learning" }}
            </h4>
            <p v-if="isCN">聚焦病例剖根源，探寻临床诊疗新路径</p>
            <p v-else>
              Focus on the root causes of the case, explore new ways of clinical diagnosis and treatment
            </p>
          </div>
          <div class="previews-item">
            <img src="@/assets/img/slices/global-live.png" alt="" />
            <h4>{{ isCN ? "全球手术演示" : "Global Live Demo" }}</h4>
            <p v-if="isCN">汇集直播和录播病例，呈现最新技术和器械</p>
            <p v-else>
              Present the latest clinical technique and devices all over the world by live and recorded cases
            </p>
          </div>
          <div class="previews-item">
            <img src="@/assets/img/slices/national-live.png" alt="" />
            <h4>{{ isCN ? "国内手术演示" : "National Live Demo" }}</h4>
            <p v-if="isCN">深化国内各中心合作与交流，展示中国瓣膜创新</p>
            <p v-else>Deepen the collaboration and communication nationwide</p>
          </div>
          <div class="previews-item">
            <img src="@/assets/img/slices/learning.png" alt="" />
            <h4>{{ isCN ? "手把手带教" : "Step By Step Learning" }}</h4>
            <p v-if="isCN">提供系统化培训，推进瓣膜中心建设</p>
            <p v-else>
              Provide systematic training for centers to carry out valvular interventions or improve techniques
            </p>
          </div>
          <div class="previews-item">
            <img src="@/assets/img/slices/research.png" alt="" />
            <h4>{{ isCN ? "基础研究论坛" : "Basic Research Forum" }}</h4>
            <p v-if="isCN">交流瓣膜领域最新基础研究成果</p>
            <p v-else>
              Exhibit and exchange latest results of basic researches on cardiac valvular diseases
            </p>
          </div>
          <div class="previews-item">
            <img src="@/assets/img/slices/multi-dimensional.png" alt="" />
            <h4>
              {{
                isCN ? "多元学习平台" : "Multi-dimensional Platform of Learning"
              }}
            </h4>
            <p v-if="isCN">覆盖解剖、CT、心超、麻醉、护理等多领域</p>
            <p v-else>
              Cover special sessions such as anatomy, CT, echocardiography, anesthesia and nursing forums
            </p>
          </div>
          <div class="previews-item">
            <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/03/29/7e95f042a1ea49fc819838237cb5e3ea.png" alt="" />
            <h4>{{ isCN ? "培训工坊" : "Workshop" }}</h4>
            <p v-if="isCN">VR、模拟器、穿刺点缝合、猪心解剖，知行合一再提升</p>
            <p v-else>
              VR, simulator operation, puncture point suture, heart anatomy, promote the unity of inner knowledge and action
            </p>
          </div>
        </div>
      </div>
    </div>
    <footer id="footer">
      <div class="container" data-aos="fade-up">
        <div class="mb-title">{{ isCN ? "联系我们" : "Contact Us" }}</div>
        <div class="row">
          <div class="hosted">
            <h5>{{ isCN ? "主办单位" : "HOSTED BY" }}</h5>
            <p>
              {{
                isCN
                  ? "浙江大学医学院附属第二医院"
                  : "Second Affiliated Hospital, Zhejiang University School of Medicine (SAHZU)"
              }}
              <br />
              {{
                isCN
                  ? "结构性心脏大会CSI"
                  : "Catheter Interventions in Congenital, Structural and Valvular Heart Diseases (CSI)"
              }}
            </p>
          </div>
          <div class="foot-bottom">
            <div class="white">
              <div class="foot-item">
                <div class="icon"><i class="bi bi-calendar2-check"></i></div>
                <div class="item-rt">
                  <h5>{{ isCN ? "会议时间：" : "CONFERENCE DATES" }}</h5>
                  <p>
                    {{
                      isCN
                        ? "2025年4月14-20日"
                        : "April.14, 2025- April.20, 2025"
                    }}
                  </p>
                </div>
              </div>
              <div class="foot-item">
                <div class="icon"><i class="bi bi-globe2"></i></div>
                <div class="item-rt">
                  <h5>{{ isCN ? "大会官网：" : "WEBSITE" }}</h5>
                  <p>http://www.china-valve.org.cn</p>
                </div>
              </div>
              <div class="foot-item">
                <div class="icon"><i class="bi bi-telephone"></i></div>
                <div class="item-rt">
                  <h5>{{ isCN ? "会务组联系电话：" : "CONTACT" }}</h5>
                  <p>
                    {{
                      isCN ? "13805784967" : "+86 13805784967"
                    }}
                  </p>
                </div>
              </div>
              <div class="foot-item">
                <div class="icon"><i class="bi bi-envelope"></i></div>
                <div class="item-rt">
                  <h5>{{ isCN ? "电子邮箱：" : "EMAIL" }}</h5>
                  <p>cvh2025@china-valve.org.cn</p>
                </div>
              </div>
              <div v-if="isCN" class="foot-item">
                <div class="icon"><i class="bi bi-geo-alt"></i></div>
                <div class="item-rt">
                  <h5>会场地点</h5>
                  <p>杭州洲际酒店（浙江省杭州市上城区解放东路2号）</p>
                </div>
              </div>
              <div class="bt">
                <a href="https://www.csi-congress.org/" target="_black" class="foot-item">
                  <div class="icon">
                    <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/05/13/f0232c8af9eb4ef9b9523856675a7cd4.png" width="50px" height="50px" style="vertical-align:top">
                  </div>
                  <div class="item-rt">
                    <h5>{{ isCN ? "合作伙伴" : "PARTNER" }}</h5>
                    <p>
                      CSI:  &nbsp;<span style="text-decoration: underline;">https://www.csi-congress.org/</span>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { selectEl, onscroll } from "../utils/util";
import Swiper from "swiper";
import "swiper/css/swiper.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "../assets/css/bootstrap-utilities.css";
import {initWeChatForwardConfiguration} from "@/utils/weChatForward";

export default {
  name: "Home",
  data() {
    return {
      isMobile: "",
      isfold: true,
      isAllText: false,
      isCN: false,
    };
  },
  created() {
    initWeChatForwardConfiguration("CHINA VALVE（HANGZHOU）2025", window.location.href, "", "2025年4月14-20日 中国·杭州")
    this.isCN = navigator.language === "zh-CN";

    const mobileAgent = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    const mobileTouch = "ontouchstart" in document.documentElement;
    const mobileMedia = window.matchMedia(
      "only screen and (max-width: 767px)"
    ).matches;
    this.isMobile = !!(mobileAgent || mobileTouch || mobileMedia);
    this.init();
  },
  methods: {
    init() {
      // 暂时关闭滚动对应tab高亮
      // window.addEventListener('load', this.navbarlinksActive);
      // document.addEventListener('scroll', this.navbarlinksActive)
      this.headerFixed();
      this.backToTopHandle();
      this.skilsContentHandle();
      if (!this.isMobile){
        this.initSwiper();
      }
      this.initAos();
    },
    navbarlinksActive() {
      let navbarlinks = selectEl("#navbar .scrollto", true);
      let position = window.scrollY + 200;
      navbarlinks.forEach((navbarlink) => {
        // console.log('navbarlink',navbarlink.hash);
        if (!navbarlink.hash) return;
        let section = selectEl(navbarlink.hash);
        if (!section) return;
        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarlink.classList.add("active");
        } else {
          navbarlink.classList.remove("active");
        }
      });
    },
    headerFixed() {
      let selectHeader = selectEl("#header");
      if (!selectHeader) {
        setTimeout(() => {
          this.headerFixed();
        }, 100);
        return;
      }
      if (selectHeader) {
        // let headerOffset = selectHeader.offsetTop
        let nextElement = selectHeader.nextElementSibling;
        let headerFixed = null;
        if (this.isMobile) {
          headerFixed = () => {
            selectHeader.classList.add("fixed-top");
            nextElement.classList.add("scrolled-offset");
            // selectHeader.classList.remove('fixed-top')
            // nextElement.classList.remove('scrolled-offset')
          };
        } else {
          headerFixed = () => {
            if (window.scrollY >= 16) {
              selectHeader.classList.add("fixed-top");
              nextElement.classList.add("scrolled-offset");
            } else {
              selectHeader.classList.remove("fixed-top");
              nextElement.classList.remove("scrolled-offset");
            }
          };
        }
        window.addEventListener("load", headerFixed);
        onscroll(document, headerFixed);
      }
    },
    backToTopHandle() {
      let backtotop = selectEl(".back-to-top");
      if (!backtotop) {
        setTimeout(() => {
          this.backToTopHandle();
        }, 100);
        return;
      }
      if (backtotop) {
        const toggleBacktotop = () => {
          if (window.scrollY > 100) {
            backtotop.classList.add("active");
          } else {
            backtotop.classList.remove("active");
          }
        };
        window.addEventListener("load", toggleBacktotop);
        onscroll(document, toggleBacktotop);
      }
    },
    skilsContentHandle() {
      let skilsContent = selectEl(".skills-content");
      if (!skilsContent) {
        setTimeout(() => {
          this.skilsContentHandle();
        }, 100);
        return;
      }
      if (skilsContent) {
        new window.Waypoint({
          element: skilsContent,
          offset: "80%",
          handler: function (direction) {
            console.log(direction);
            let progress = selectEl(".progress .progress-bar", true);
            progress.forEach((el) => {
              el.style.width = el.getAttribute("aria-valuenow") + "%";
            });
          },
        });
      }
    },
    initSwiper() {
      console.log("initSwiper", selectEl(".testimonials-slider"));
      const tEl = selectEl(".testimonials-slider");
      // const pEl = selectEl(".portfolio-details-slider")
      if (!tEl) {
        setTimeout(() => {
          this.initSwiper();
        }, 100);
        return;
      }
      new Swiper(".testimonials-slider", {
        speed: 600,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        slidesPerView: "auto",
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });
      // new Swiper(".portfolio-details-slider", {
      //   speed: 400,
      //   loop: true,
      //   autoplay: {
      //     delay: 5000,
      //     disableOnInteraction: false,
      //   },
      //   pagination: {
      //     el: ".swiper-pagination",
      //     type: "bullets",
      //     clickable: true,
      //   },
      // });
    },
    initAos() {
      AOS.init({
        duration: 1000,
        easing: "ease-in-out",
        once: true,
        mirror: false,
      });
    },
    scrollto(el) {
      let header = selectEl("#header");
      let offset = header.offsetHeight;

      if (!header.classList.contains("header-scrolled")) {
        offset -= 16;
      }

      let elementPos = selectEl(el).offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: "smooth",
      });
    },
    mobileNavToggle() {
      selectEl("#navbar").classList.toggle("navbar-mobile");
      selectEl(".mobile-nav-toggle").classList.toggle("bi-list");
      selectEl(".mobile-nav-toggle").classList.toggle("bi-x");
    },
    navSelectHandle(e, val) {
      if (val) {
        console.log(val);
        e.preventDefault();

        selectEl(val).scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });

        // window.location.hash = val;

        let navbar = selectEl("#navbar");
        if (navbar.classList.contains("navbar-mobile")) {
          navbar.classList.remove("navbar-mobile");
          let navbarToggle = selectEl(".mobile-nav-toggle");
          navbarToggle.classList.toggle("bi-list");
          navbarToggle.classList.toggle("bi-x");
        }
      }
    },
    dropdownHandle() {
      selectEl(".huigu").classList.toggle("dropdown-active");
    },
    dropdownHandles() {
      selectEl(".presenter").classList.toggle("dropdown-active");
    },
    forumsDropdownHandle(){
      selectEl(".forums").classList.toggle("dropdown-active");
    },
    goPrevious(year) {
      let url = "http://cvh" + year + ".china-valve.org.cn/";
      if (!this.isCN) {
        url += "?language=en";
      }
      window.open(url);
    },
    goSignUp() {
      /*location.href = this.isCN
        ? "https://www.drvoice.cn/v2/conf/1120/delegationRegister"
        : "https://www.drvoice.cn/v2/conf/1120/delegationRegister?language=en";*/
    },
    handleChange(val) {
      location.href = val;
    },
  },
};
</script>

<style scoped>
.container-mb .scrolled-offset {
  margin-top: 50px;
}
.container-mb #header {
  height: 50px;
  /* background-color: #1015a3; */
  background-image: linear-gradient(to right, #0545b5, #000c60);
  background-color: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.container-mb #header .navbar .mobile-nav-toggle {
  color: #fff;
  /* color: #193db3; */
}
.container-mb .lang-check {
  color: #193db3;
  background-color: #fff;
  /* border: 1px solid #193db3; */
  border-radius: 4px;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  width: 78px;
  margin-right: 20px;
  margin-left: 80px;
  font-weight: 700;
}

.container-mb .banner h1 {
  position: relative;
  z-index: 1;
  margin: 0;
  font-size: 26px;
  font-weight: 700;
  color: #222222;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  background: linear-gradient(
    0deg,
    #ffebb9 0%,
    #ffd867 65.9912109375%,
    #ffeaa7 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.container-mb .banner .yy {
  width: 100%;
  position: absolute;
  top: 36px;
  left: 0;
  color: transparent;
  font-size: 26px;
  font-weight: 700;
  text-shadow: -3px 5px 5px rgba(0, 0, 0, 0.3), -2px -3px 0 #000;
}

.container-mb .banner h2 {
  color: #555555;
  margin: 20px 0 5px 0;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
}
.container-mb .banner .logo img {
  width: 100px;
}
.container-mb #hero .box {
  /* border-top: 1px solid #2c48c8;
    border-bottom: 1px solid #2c48c8; */
}
.container-mb #hero .img-list {
  display: flex;
  align-items: center;
  overflow-y: hidden;
  flex-wrap: nowrap;
}

.container-mb #hero .img-list .img-box {
  border: 1px solid #2c48c8;
  margin: 0 5px;
  list-style: none;
  white-space: nowrap;
  width: 45vw;
  padding: 2vw;
}
.container-mb #hero .img-list img {
  width: 41vw;
}

.container-mb .programe {
  padding-top: 60px;
}
.container-mb .mb-title {
  font-weight: 700;
  font-size: 26px;
  line-height: 3em;
  text-align: center;
  color: #02315a;
}
.container-mb .programe .programe-item {
  display: flex;
  padding: 10px 32px;
  justify-content: space-between;
}
.container-mb .programe .programe-item .item-time {
  font-weight: 700;
  font-size: 20px;
  /* line-height: 2em; */
  color: #444446;
}
.container-mb .programe .programe-item .item-text {
  font-weight: 400;
  font-size: 20px;
  /* line-height: 2em; */
  color: #2a2a2a;
}
.container-mb .programe .programe-item .icon {
  width: 50px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0e6dea;
}
.container-mb .welcoming .p {
  font-size: 17px;
  padding: 0 30px;
  width: 100%;
  margin-bottom: 1em;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #666666;
}
.container-mb .welcoming .isfold {
  position: relative;
  height: 188px;
  width: 100%;
  overflow: hidden;
}
.container-mb .welcoming .isfold::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 24px;
  width: 100%;
  background-color: rgb(255, 255, 255, 0.7);
}
.container-mb .welcoming .switch {
  padding: 10px 0;
  text-align: center;
  font-size: 18px;
  line-height: 1em;
  color: #02315a;
}
.container-mb .previews .previews-list {
  display: flex;
  overflow-y: hidden;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
}
.container-mb .previews .previews-list .previews-item {
  width: 45vw;
  flex-shrink: 0;
  list-style: none;
  white-space: nowrap;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  border: 1px solid #dcdcdc;
}
.container-mb .previews .previews-list img {
  border-radius: 10px;
  width: 100%;
}
.container-mb .previews .previews-list h4 {
  padding-top: 10px;
  font-size: 15px;
  font-weight: 700;
  color: #222120;
  white-space: pre-wrap;
}
.container-mb .previews .previews-list p {
  font-size: 14px;
  white-space: pre-wrap;
  margin: 0;
}
.container-mb .testimonials {
  padding: 20px 0;
  background: none;
}
.container-mb .testimonials .testimonial-item h3 {
  color: #41708c;
}
.container-mb .testimonials .testimonial-item h4 {
  color: #333;
}
.container-mb .testimonials .testimonial-item p {
  margin: 0 auto 15px auto;
  font-style: italic;
  color: #08314f;
}

.container-mb #footer .hosted {
  text-align: center;
}
.container-mb #footer .hosted h5 {
  color: #3498db;
}
.container-mb #footer h5 {
  font-size: 15px;
  color: #2d2d2d;
}
.container-mb #footer p {
  font-size: 13px;
  color: #2d2d2d;
}
.container-mb .foot-bottom {
}
.container-mb .white {
  background: #fff;
  padding: 16px 0 25px;
}
.container-mb .white .foot-item {
  display: flex;
  margin-bottom: 20px;
}
.container-mb .white .foot-item h5,
.container-mb .white .foot-item p {
  margin-bottom: 0;
  line-height: 1.8em;
}

.container-mb .white .foot-item .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  font-size: 30px;
  line-height: 1em;
  color: #3498db;
}
.container-mb .white .foot-item .item-rt {
  flex: 1;
}
.container-mb .sign-list {
  display: flex;
  text-align: center;
  justify-content: space-around;
}
.container-mb .sign-list img {
  width: 80%;
}
.sign-img {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#hero {
  width: 100%;
  position: relative;
  /* padding: 120px 0 0 0; */
  padding: 40px 0 0 0;
  /* padding-top: 20px; */
  background: url("../assets/img/slices/hero-bg.png");
  background-size: cover;
}
.container-mb #hero {
  background-image: linear-gradient(to right, #0545b5, #000c60);
}

/* #hero:before {
  content: "";
  background: rgba(2, 5, 161, 0.91);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
} */

#hero h1 {
  position: relative;
  z-index: 1;
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  color: #222222;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  background: linear-gradient(
    0deg,
    #ffebb9 0%,
    #ffd867 65.9912109375%,
    #ffeaa7 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#hero .yy {
  position: absolute;
  top: 0;
  left: 0;
  color: transparent;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  text-shadow: -3px 5px 5px rgba(0, 0, 0, 0.3), -2px -3px 0 #000;
}

#hero h2 {
  color: #555555;
  margin: 20px 0 20px 0;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 991px) {
  #hero {
    /* padding-top: 80px; */
  }
  #hero .text {
    text-align: center;
    width: 100%;
  }
  #hero .hero-img {
    text-align: center;
  }

  #hero .hero-img img {
    max-width: 50%;
  }

  #hero h1 {
    font-size: 23px;
    line-height: 32px;
    margin-bottom: 10px;
  }
  #hero .yy {
    font-size: 23px;
    line-height: 32px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

.hero-waves {
  display: block;
  margin-top: 60px;
  width: 100%;
  height: 60px;
  z-index: 5;
  position: relative;
}

.wave1 use {
  -webkit-animation: move-forever1 10s linear infinite;
  animation: move-forever1 10s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave2 use {
  -webkit-animation: move-forever2 8s linear infinite;
  animation: move-forever2 8s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave3 use {
  -webkit-animation: move-forever3 6s linear infinite;
  animation: move-forever3 6s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

@-webkit-keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }

  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }

  100% {
    transform: translate(-90px, 0%);
  }
}

@-webkit-keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

@-webkit-keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}
.CVH-mb {
  min-height: 100vh;
  background-color: #d9ecf9;
}

.CVH-mb img {
  border: none;
  vertical-align: middle;
  outline: none;
  width: 100%;
}
.CVH-mb .container {
  margin: 0 auto;
  padding: 0 0;
  width: 100%;
}
.CVH-mb .wap-grid {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}
.CVH-mb .wap-grid-6 {
  width: 50%;
  float: left;
}
.CVH-mb .wap-grid-4 {
  width: 33.33333333%;
  float: left;
}
.CVH-mb .wap-grid-8 {
  width: 66.66666666%;
  float: left;
}
.CVH-mb .wap-grid-12 {
  width: 100%;
  float: left;
}
</style>
